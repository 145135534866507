import React from "react";
import Seo from "../../../components/Seo/seo";
import Layout from "../../../components/Layout/layout";
// import HeroSection from "../../../components/KSLiveRegistration/HeroSection";
// import HighlightBar from "../../../components/KSLiveRegistration/HighlightBar";
import NewHeroSection from "../../../components/KSLiveRegistration/HeroSection/new-hero-section";
import NewHighlightBar from "../../../components/KSLiveRegistration/HighlightBar/new-highlight-bar";

const RegisterPage = (props) => {
  return (
    <>
      <Seo noIndex = {true} />
      <Layout formLink="/">
        <div className="ks-live-registration-main">
          {/* <HeroSection params={props.params} /> */}
          <NewHeroSection params={props.params} />
          {/* <HighlightBar /> */}
          <NewHighlightBar />
          {/* <Reviews /> */}
        </div>
      </Layout>
    </>
  );
};

export default RegisterPage;
